import React from 'react';

export const Footer = (props) => {
    return (
        <>
            <footer className="footer"></footer>
            <div className="container-fluid  request">
                <div className="container">
                    <div align="center" className='footer-1' >Book Your RO Service</div>
                    &nbsp;

                    <div className="col-md-12 col-sm-4 col-xs-12">
                        
                            <p align="center">
                            <a href="tel:18005712225 ">
                                <button className="btn btn-primary btn-lg btn-1" id="btn-1" type="button">18005712225 </button>
                            </a>
                        </p>
                            
                    </div>

                    <p>Disclaimer: RO CARE HELPLINE in Delhi & All India is an independent Service centre. RO CARE HELPLINE in All India's Largest &amp; Fastest Growing Service Provider &amp; We undertake job work of Service, AMC, Repair &amp; Maintenance of RO Systems, RO Service in All India is totally responsible for these and no relation to parent company in any regards.</p>
                </div>
            </div>
        </>
    )
}
